import React from "react"
import { EventoCard } from "./EventoCard"
import moment from "moment"
import "moment/locale/es"
import ordenarEventos from "../../helpers/ordenarEventos"

const GrillaEvento = ({ state, setState, variablesEntorno }) => {
  moment.locale("es")

  const handleClickCargarMas = () => {
    setState(s => ({ ...s, gruposMostrados: s.gruposMostrados + 1 }))
  }
  // console.log(`GrillaEvento state.eventos.length: ${state.eventos.length}`);

  const eventosOrdenados = ordenarEventos([...state.eventos])

  const grupos = new Array(Math.ceil(eventosOrdenados.length / 9))
    .fill()
    .map(_ => eventosOrdenados.splice(0, 9))

  return (
    <>
      <div className="events-wrapper-general">
        {grupos.map(
          (grupo, index) =>
            index < state.gruposMostrados &&
            grupo.map(item => (
              <EventoCard
                key={item.evento.id}
                {...item}
                variablesEntorno={variablesEntorno}
              />
            ))
        )}
      </div>
      <div className="cont-btn-more">
        {grupos.length > state.gruposMostrados && (
          <button
            className="btn-blue outline w-button new-button"
            onClick={handleClickCargarMas}
          >
            cargar más eventos
          </button>
        )}
      </div>
    </>
  )
}

export default GrillaEvento
